import type { BaseAPIAuthProps, SecuritySelectorProps } from '../types';

import React, { useMemo, useEffect } from 'react';

import useClassy from '@core/hooks/useClassy';
import { supportedOAuthFlows } from '@core/store/Reference/Auth/oauth-types';

import SectionHeader from '@ui/API/SectionHeader';
import Box from '@ui/Box';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Menu from '@ui/Menu';
import MenuItem from '@ui/Menu/Item';
import RDMD from '@ui/RDMD';

import { InfoBadgeParent, InfoTooltip } from '../components/Info';
import { useSecurityGroups } from '../useSecurityGroups';

import AuthInput from './components/input';
import classes from './style.module.scss';
import './style.scss';

const SecuritySelector = ({ securityGroupIndex, securityGroups, setSecurityGroupIndex }: SecuritySelectorProps) => {
  const bem = useClassy(classes, 'APIAuth');

  const hasMultipleSecurityGroups = securityGroups.length > 1;

  const buttonTitle = `${securityGroups[securityGroupIndex][0].type}${
    securityGroups[securityGroupIndex].length > 1 ? ` +${securityGroups[securityGroupIndex].length - 1}` : ''
  }`;

  const button = (
    <button
      className="Button Button_sm InputGroup-button"
      data-testid="api-auth-security-button"
      disabled={!hasMultipleSecurityGroups}
    >
      <span>{buttonTitle}</span>
      {!!hasMultipleSecurityGroups && <i className="icon-chevron-down" />}
    </button>
  );

  const opts = useMemo(() => ({ copyButtons: false }), []);

  const menuItemContent = group => (
    <>
      <div>{`${group[0].type}`}</div>
      {group.length > 1 && <div className={bem('-menu-subtitle')}>{`+ ${group.length - 1} more…`}</div>}
      {group.length === 1 && !!group[0].security.description && (
        <RDMD className="subtitle" opts={opts}>
          {group[0].security.description}
        </RDMD>
      )}
    </>
  );

  return (
    <Dropdown className="InputGroup-dropdown" clickInToClose justify="start" trigger="click">
      {button}
      <Box kind="pop" style={{ padding: 0, width: 'max-content' }}>
        <Menu>
          {securityGroups.map((group, i) => (
            <MenuItem
              key={`${group[0].type}-${i}`}
              aria-selected={i === securityGroupIndex}
              className={bem('-menu-item')}
              id={`security-${i}`}
              onClick={() => setSecurityGroupIndex(i)}
              role="option"
            >
              {menuItemContent(group)}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Dropdown>
  );
};

const APIAuth = ({
  apiKey,
  auth,
  customLoginEnabled,
  group,
  groups,
  isGroupLoggedIn,
  inputRef,
  onAuthGroupChange,
  oauth,
  operation,
  pathname = '/',
  selectedAuth,
  updateAuth,
  updateSecurityGroup,
}: BaseAPIAuthProps) => {
  const bem = useClassy(classes, 'APIAuth');

  const { securityGroups, securityGroupIndex, currentSecurityGroup, setSecurityGroupIndex } = useSecurityGroups(
    operation,
    selectedAuth,
  );

  const hasAuth = Boolean(currentSecurityGroup && currentSecurityGroup.length);

  useEffect(() => {
    if (updateSecurityGroup) updateSecurityGroup(currentSecurityGroup, hasAuth);
  }, [currentSecurityGroup, hasAuth, updateSecurityGroup]);

  useEffect(() => {
    if (currentSecurityGroup && inputRef?.current) {
      inputRef.current = (inputRef?.current || []).slice(0, currentSecurityGroup.length);
    }
  }, [currentSecurityGroup, inputRef]);

  const currentSecurityGroupHasOAuth = (currentSecurityGroup || []).some(secGroup => secGroup.type === 'OAuth2');
  const containsLegacyOAuth = oauth === 'legacy-enabled' && currentSecurityGroupHasOAuth;
  const containsOAuthFlows =
    oauth === 'enabled' &&
    (currentSecurityGroup || []).some(
      secGroup =>
        secGroup.security.type === 'oauth2' &&
        // Check if any of the supported OAuth flow types are present in the security scheme
        Object.keys(secGroup.security.flows || {}).some(flow => Object.keys(supportedOAuthFlows).includes(flow)),
    );

  const heading = useMemo(
    () => (
      <>
        Authorization
        {securityGroups.length === 1 && currentSecurityGroup.length === 1 && (
          <InfoTooltip auth={auth} oAuthFlowsEnabled={containsOAuthFlows} security={currentSecurityGroup[0]} />
        )}
      </>
    ),
    [auth, containsOAuthFlows, currentSecurityGroup, securityGroups.length],
  );

  if (!hasAuth || !currentSecurityGroup || currentSecurityGroup.length === 0) return null;

  const isLoggedIn = (groups && groups.length) || isGroupLoggedIn;

  return (
    <section className="APIAuth rm-APIAuth" data-testid="api-auth">
      <SectionHeader heading={heading}>
        {securityGroups.length === 1 && currentSecurityGroup.length === 1 && (
          <InfoBadgeParent auth={auth} oAuthFlowsEnabled={containsOAuthFlows} security={currentSecurityGroup[0]} />
        )}
      </SectionHeader>
      <Flex
        align="stretch"
        className={[
          'InputGroup',
          `${currentSecurityGroup.length > 1 || currentSecurityGroupHasOAuth ? 'InputGroup-multiple' : ''}`,
          `${currentSecurityGroupHasOAuth ? 'InputGroup-oauth' : ''}`,
          `${containsOAuthFlows ? 'InputGroup-oauth-flows' : ''}`,
        ].join(' ')}
        gap="xs"
        layout={currentSecurityGroup.length > 1 || currentSecurityGroupHasOAuth ? 'col' : 'row'}
        tag="section"
      >
        {!containsOAuthFlows && (
          <Flex className="InputGroup-dropdown-parent" gap="xs" tag="header">
            <SecuritySelector
              securityGroupIndex={securityGroupIndex}
              securityGroups={securityGroups}
              setSecurityGroupIndex={setSecurityGroupIndex}
            />
            {!!containsLegacyOAuth && (
              <a
                className={bem(
                  '-oauthButton',
                  isLoggedIn ? '-oauthButton_logOut' : '-oauthButton_authorize',
                  'rm-APIAuth-oauthButton',
                )}
                href={`/${isLoggedIn ? 'logout' : 'oauth'}?redirect_uri=${pathname}`}
                rel="noreferrer"
                target="_blank"
              >
                {isLoggedIn ? 'Log Out' : 'Authenticate'}
              </a>
            )}
          </Flex>
        )}
        {currentSecurityGroup.map((security, i) => (
          <AuthInput
            key={security.security._key}
            apiKey={apiKey}
            auth={auth}
            displayTooltip={securityGroups.length > 1 || currentSecurityGroup.length > 1}
            group={group}
            groups={groups}
            inputIndex={i}
            inputRef={inputRef}
            oauth={oauth}
            onAuthGroupChange={onAuthGroupChange}
            onChange={updateAuth}
            security={security}
          />
        ))}
      </Flex>
      {!isLoggedIn && !!customLoginEnabled && (
        <Flex align="center" className={bem('-login', 'rm-APIAuth-login')} gap="sm" justify="start">
          <span aria-hidden="true" className={bem('-login-icon', 'icon-key1')} />
          <a
            className={bem('-login-link')}
            data-testid="api-auth-login-link"
            href={`/login?redirect_uri=${pathname}`}
            rel="noreferrer"
          >
            Log in to use your API keys
          </a>
        </Flex>
      )}
    </section>
  );
};

export default React.memo(APIAuth);
