import type { $TSFixMe } from '@readme/iso';
import type { Operation } from 'oas/operation';

import loadable from '@loadable/component';
// eslint-disable-next-line readme-internal/no-restricted-imports
import { maskCredential } from '@readme/server-shared/metrics/mask-credential';
import { format } from 'date-fns';
import qs from 'qs';
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { validate as uuidValidate, v4 as uuid } from 'uuid';

import useMetrics from '@core/hooks/useMetrics';
import useMetricsAPI from '@core/hooks/useMetricsAPI';
import { useReferenceStore } from '@core/store';
import { stringifyOptions } from '@core/store/Metrics/constants';
import type { ListResponse } from '@core/types/metrics';

import usePollMetrics from '@routes/Reference/Realtime/components/usePollMetrics';

import type Modal from '@ui/Modal';

import SharedLogErrorModal from './SharedLogErrorModal';

const APILogsTable = loadable(() => import('@ui/API/LogsTable'), { ssr: false });
// Only displaying the first 3 results from first page
const PAGE_SIZE = 3;
const PAGE = 0;

interface TableContainerProps {
  ephemeralHAR: $TSFixMe | boolean;
  footer: React.ReactNode;
  onSelectLog: (log: { id: string | null; response?: $TSFixMe }) => void;
  operation: Operation;
  url: string;
}

function TableContainer({ ephemeralHAR, operation, onSelectLog, url: queryUrl, footer }: TableContainerProps) {
  // Metrics Id param is passed when navigating to an endpoint page from elsewhere in our app
  // Navigation sources include My Developers and My Requests
  const metricId = useMemo(() => {
    const routeQueryParams = new URLSearchParams(window.location.search);
    const routeInferredId = routeQueryParams.get('metricId') || '';
    return uuidValidate(routeInferredId) ? routeInferredId : null;
  }, []);

  const groupId = useReferenceStore(s => s.auth.hashedGroup);
  const { isDevDashEnabled } = useMetrics();

  const [logDateTime, setLogDateTime] = useState<string>();
  const [selectedLogId, setSelectedLogId] = useState<string | null>(null);
  const [responseAccepted, setResponseAccepted] = useState(false);
  const errorModalRef = useRef<Modal | null>(null);

  useEffect(() => {
    if (metricId) {
      setSelectedLogId(metricId);
    }
  }, [metricId, selectedLogId]);

  const listParams = useMemo(() => {
    return qs.stringify(
      {
        groupId,
        method: operation.method,
        page: PAGE,
        pageSize: PAGE_SIZE,
        rangeLength: isDevDashEnabled ? 30 : 24,
        resolution: isDevDashEnabled ? 'day' : 'hour',
        url: queryUrl,
      },
      stringifyOptions,
    );
  }, [groupId, isDevDashEnabled, operation.method, queryUrl]);

  const acceptResponse = (prevResponse: ListResponse | undefined, nextResponse: ListResponse) => {
    const accepted =
      nextResponse.length > (prevResponse?.length || 0) ||
      (nextResponse.length && prevResponse?.length && nextResponse?.[0]?.id !== prevResponse?.[0]?.id) ||
      false;

    if (accepted) setResponseAccepted(accepted);
    return accepted;
  };

  const { data: logs, isLoading } = usePollMetrics(`requests/list?${listParams}`, ephemeralHAR, acceptResponse);

  const logDetailQuery = useMemo(
    () =>
      qs.stringify(
        {
          groupId: logDateTime && !metricId ? groupId : undefined,
          createdAt: logDateTime ? new Date(logDateTime).toISOString() : undefined,
        },
        stringifyOptions,
      ),
    [groupId, logDateTime, metricId],
  );

  const { data: logResponse, error } = useMetricsAPI<$TSFixMe>(
    `requests/${selectedLogId}?${logDetailQuery}`,
    !!selectedLogId,
  );

  useEffect(() => {
    onSelectLog({ id: selectedLogId, response: logResponse });
  }, [selectedLogId, logResponse, onSelectLog]);

  useEffect(() => {
    if (error) errorModalRef.current?.toggle(true);
  }, [error]);

  const handleRowSelect = useCallback(
    (id, log) => {
      const createdAt = log?.createdAt ? new Date(log.createdAt).toISOString() : undefined;
      setLogDateTime(createdAt);
      setSelectedLogId(id);

      if (id == null) return onSelectLog({ id: null });
      return null;
    },
    [onSelectLog],
  );

  // Mock `/request/lists` response
  // If for some reason, we're unable to "connect the dots" and find resulting Metrics data
  // We still want to show end users the experience as a tease.
  const mockListResponse = useMemo(() => {
    if (!ephemeralHAR?.log || !operation.method) return undefined;

    const { response } = ephemeralHAR.log.entries[0];
    const { status } = response;
    const { method, path, schema } = operation;
    const { operationId = '' } = schema || {};

    return [
      {
        id: uuid(),
        groupId: maskCredential(groupId),
        groupEmail: '',
        groupLabel: '',
        createdAt: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        startedDateTime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        status,
        method,
        path,
        url: '',
        operationId,
        company: {},
        useragent: {
          name: 'ReadMe-API-Explorer',
          platform: 'ReadMe',
        },
      },
    ];
  }, [ephemeralHAR?.log, groupId, operation]);

  // Only display mocks if there are no logs from Metrics
  const displayMockList = useMemo(() => {
    return !logs?.length && !responseAccepted;
  }, [logs?.length, responseAccepted]);

  return (
    <>
      <APILogsTable
        key={`table-${queryUrl}-${operation.method}`}
        featuredLog={logResponse?.log}
        footer={footer}
        isLoading={isLoading}
        logs={!displayMockList ? logs : mockListResponse}
        onRowSelect={handleRowSelect}
        selectedRowId={!displayMockList ? selectedLogId : mockListResponse?.[0]?.id}
      />
      <SharedLogErrorModal ref={errorModalRef} />
    </>
  );
}

export default TableContainer;
